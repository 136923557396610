const localeConfig = JSON.parse(localStorage.getItem('locale_config')) || {};

export const getLangCode = localeConfig?.lang_locale ?? "";
export const getCountryId = localeConfig?.country_id ?? "";
export const getSiteName = localeConfig?.site_name ?? "";
export const getCurrencyCode = localeConfig?.currency_currency_code ?? "";
export const getTaxTitle = localeConfig?.tax_tax_title ?? "%";
export const getLoadingGif = localeConfig?.loading_gif ?? "";
export const getContentIds = localeConfig?.content_ids ?? "";
export const getCategoriesTitle0Vat = localeConfig?.categories_title_0_vat ?? "";
