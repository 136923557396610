import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import faErrorStrings from '../../assets/files/faErrorStrings.json';

const FaErrorPage = () => {
    // Get the current website URL
    const currentURL = window.location.hostname;
    // Determine the termination of the website URL
    let termination = "";
    if (currentURL.endsWith(".de")) {
        termination = "de"; // German
    } else if (currentURL.endsWith(".at")) {
        termination = "at"; // Austria
    } else if (currentURL.endsWith(".ro")) {
        termination = "ro"; // Romanian
    }
    // Select the sorry message based on the termination or default to English
    const selectedMessage = faErrorStrings[termination] || faErrorStrings["en"];

    return (
        <div className="error-page-container">
            <div className="error-content">
                <div className="parent">
                    <div className="child child-behind">
                        <i className="pe-7s-settings screw"></i>
                    </div>
                    <div className="child child-front">
                        <div className="screen-wrapper">
                            <div className="screen">
                                <div className="window-app-wrapper">
                                    <div className="window-bar">
                                        <FontAwesomeIcon icon="fa-solid fa-circle" style={{ color: "#ff6666", }} />
                                        <FontAwesomeIcon icon="fa-solid fa-circle" style={{ color: "#ffcf64", }} />
                                        <FontAwesomeIcon icon="fa-solid fa-circle" style={{ color: "#34ea71", }} />
                                    </div>
                                    <div className='window-app'>
                                        <div className='in-app-logo'>
                                            {
                                                selectedMessage.image ?
                                                  <img src={process.env.PUBLIC_URL + selectedMessage.image}
                                                       alt='logo' />
                                                  :
                                                  <FontAwesomeIcon icon='fa-solid fa-screwdriver-wrench' />
                                            }
                                        </div>
                                        <p id='hiddenParagraph' style={{ display: "none" }}>4355891751</p>
                                        <div className='in-app-contact'>
                                            <div className='contact-row'>
                                                {selectedMessage.contact_message}
                                            </div>
                                            {selectedMessage.phone &&
                                              <div className='contact-row'>
                                                  <a href={'tel:' + selectedMessage.phone}>
                                                      <i className='fa fa-phone' />
                                                      {selectedMessage.phone}
                                                  </a>
                                                  {selectedMessage.schedule &&
                                                    <span className='schedule'>{selectedMessage.schedule}</span>
                                                  }
                                              </div>
                                            }
                                            {selectedMessage.mail &&
                                              <div className='contact-row'>
                                                  <i className='fa fa-envelope' />
                                                  {selectedMessage.mail}
                                              </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='child child-front-down'>
                        <FontAwesomeIcon icon="fa-regular fa-circle" />
                    </div>
                </div>
                <h1 className="sorry-message-title">{selectedMessage.error_title}</h1>
                <p style={{ margin: 0 }}>{selectedMessage.error_message}</p>
                <p><b>{selectedMessage.error_advice}</b></p>
            </div>
        </div >
    );
};

export default FaErrorPage;