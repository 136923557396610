import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    userLoggedIn: false,
    userInfo: null,
    token: null,
    user_identifier: null,
		// Check if the user is an admin
    isAdmin: false,
    isCompany: false,
    excludeVAT: null,
    profileName: '',
    customer_id: null,
    address_id: null,
    user_express: null,
    user_express_data: null,
    country_id: null,
};

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUserInfo: (state, action) => {
            state.userInfo = action.payload;
        },
        setTitle: (state, action) => {
            state.userInfo.title_id = action.payload.t1;
            state.userInfo.title = action.payload.t2;
        },
        setToken: (state, action) => {
            const currentTime = new Date();
            const thirtyMinutesFromNow = new Date(currentTime.getTime() + 360 * 60000);

            state.token = { token: action.payload, expiresAt: thirtyMinutesFromNow };

            if (state.user_express) {
                state.user_express = null;
                if (state.user_express_data) {
                    state.user_express_data = null;
                }
            }
        },
        setUserIdentifier: (state, action) => {
            state.user_identifier = action.payload;
        },
        setIsCompany: (state, action) => {
            state.isCompany = action.payload;
        },
        setIsAdmin: (state, action) => {
            state.isAdmin = action.payload;
        },
        setIsExcludeVAT: (state, action) => {
            state.excludeVAT = action.payload;
        },
        setProfileName: (state, action) => {
            state.profileName = action.payload;
        },
        setUserLoggedIn: (state) => {
            state.userLoggedIn = !state.userLoggedIn;
        },
        setEmpty: state => {
            state.userInfo = null;
            state.token = null;
            state.user_identifier = null;
            state.isAdmin = false;
            state.isCompany = false;
            state.excludeVAT = false;
            state.userLoggedIn = false;
            state.profileName = '';
            state.customer_id = null;
            state.address_id = null;
            state.country_id = null;
        },
        setCustomerId: (state, action) => {
            state.customer_id = action.payload;
        },
        setAddressId: (state, action) => {
            state.address_id = action.payload;
        },
        setCountryId: (state, action) => {
            state.country_id = action.payload;
        }
    },
});

export const {
    setUserInfo,
    setTitle,
    setToken,
    setUserIdentifier,
    setIsAdmin,
    setIsCompany,
    setIsExcludeVAT,
    setUserLoggedIn,
    setEmpty,
    setProfileName,
    setAddressId,
    setCustomerId,
    setCountryId
} = userSlice.actions;
export default userSlice.reducer;
